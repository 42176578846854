@charset "UTF-8";
.post-playlist .cover .title {
  font-size: 108px;
  line-height: 123px;
  font-family: "Tiempos Fine";
  text-transform: none;
  font-weight: 400;
  padding: 0; }

.post-playlist .cover .desc {
  font-family: "National 2";
  font-weight: 400;
  font-size: 28px;
  line-height: 36px; }

.post-playlist .cover .container {
  max-width: 840px;
  text-align: left; }

.post-playlist .cover .desc {
  max-width: 100%; }

.post-playlist .playlist-chapters li {
  list-style: none;
  padding-left: 10px; }
  .post-playlist .playlist-chapters li a {
    color: #999;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
    display: inline-block;
    position: relative;
    padding-left: 22px; }
    .post-playlist .playlist-chapters li a:before {
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 2px solid #999;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0; }
    .post-playlist .playlist-chapters li a .index-count {
      display: none; }
    .post-playlist .playlist-chapters li a .tooltip {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      font-family: "Tiempos Text";
      width: 215px;
      padding: 5px;
      background: #fff;
      top: 50%;
      line-height: 29px;
      transform: translateY(-50%);
      font-size: 14px;
      line-height: 16px;
      left: 116%;
      color: black;
      padding: 17px 13px;
      border-radius: 5px;
      transition: all .5s;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
      z-index: 11; }
      .post-playlist .playlist-chapters li a .tooltip:before {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5.5px 7px 5.5px 0;
        border-color: transparent #fff transparent transparent;
        left: -7px;
        top: 50%;
        transform: translateY(-50%); }
    .post-playlist .playlist-chapters li a:hover .tooltip {
      opacity: 1;
      visibility: visible; }
  .post-playlist .playlist-chapters li.is-current a {
    color: #000; }
    .post-playlist .playlist-chapters li.is-current a:before {
      background-color: #1a17af;
      border-color: #1a17af; }

.post-playlist-container {
  max-width: 1400px;
  margin: 100px auto; }
  .post-playlist-container aside.sidebar {
    position: sticky;
    top: 100px;
    margin-top: 5px;
    float: left;
    z-index: 10;
    width: 200px; }

.post-playlist-content .text-block {
  max-width: 840px;
  margin: auto; }
  .post-playlist-content .text-block img {
    max-width: 100%; }

.post-playlist-content .size-small {
  max-width: 920px;
  margin: 0 auto 10px;
  padding: 0 15px; }

.post-playlist-content .cover-block {
  width: 100%;
  margin: 0 auto 8px;
  padding-bottom: 46.3%;
  height: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover; }
  .post-playlist-content .cover-block.small-background {
    height: 250px;
    width: 250px;
    padding: 0;
    margin: 0 0 8px 0; }

.cover-block {
  height: 648px;
  position: relative; }
  .cover-block .cover-block-text {
    font-family: "Tiempos Fine";
    font-size: 61px;
    line-height: 73px;
    font-weight: 300;
    position: relative;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    max-width: 920px;
    width: 100%;
    text-align: center; }
    .cover-block .cover-block-text:before {
      content: '“';
      font-family: inherit;
      display: none; }
    .cover-block .cover-block-text:after {
      content: '”';
      font-family: inherit;
      display: none; }

.playlist-back-btn,
.playlist-next-btn,
.hamburger {
  display: none; }

@media only screen and (max-width: 1300px) {
  .post-playlist-content .text-block {
    max-width: 1200px;
    margin: auto;
    margin-left: 200px; }
  .post-playlist-content .cover-block .cover-block-text {
    max-width: 600px;
    font-size: 50px;
    line-height: 60px; } }

@media only screen and (max-width: 992px) {
  .post-playlist-container {
    margin: 50px 0; }
  .post-playlist-content .cover-block .cover-block-text {
    max-width: 400px;
    font-size: 30px;
    line-height: 40px; }
  .cover-block {
    height: 500px; }
    .cover-block .cover-block-text {
      font-size: 55px;
      line-height: 58px;
      padding: 0 15px; }
  .post-playlist-content .default-css {
    margin: 0 auto; }
  .post-playlist .cover .title {
    font-size: 70px;
    line-height: 80px; } }

@media only screen and (max-width: 768px) {
  .post-playlist-content {
    width: auto; }
    .post-playlist-content .wp-block-image.alignfull {
      left: 0;
      width: auto;
      background-position: top left; }
  .playlist-back-btn,
  .playlist-next-btn,
  .hamburger {
    display: block;
    cursor: pointer; }
  .post-playlist .cover {
    min-height: 335px !important;
    height: auto !important;
    padding: 70px 0 20px 0; }
    .post-playlist .cover .title {
      font-size: 60px;
      line-height: 70px; }
    .post-playlist .cover .desc {
      margin-top: 0; }
  .post-playlist-container aside.sidebar {
    width: auto; }
  .post-playlist-content .text-block {
    margin-left: 0; }
  .post-playlist .playlist-chapters {
    width: 100%;
    position: fixed;
    background-color: #fff;
    top: unset;
    bottom: 0;
    margin-top: 0px;
    height: 60px;
    border-top: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    transition: height 0.35s; }
    .post-playlist .playlist-chapters.open {
      height: 70%;
      padding: 20px 0 10px; }
      .post-playlist .playlist-chapters.open .playlist-index {
        height: 100%; }
        .post-playlist .playlist-chapters.open .playlist-index ul li {
          display: block; }
          .post-playlist .playlist-chapters.open .playlist-index ul li a {
            margin: 0 0 15px; }
          .post-playlist .playlist-chapters.open .playlist-index ul li:last-child a {
            margin: 0; }
    .post-playlist .playlist-chapters .playlist-index {
      padding-left: 45px;
      width: 100%;
      padding-right: 45px;
      overflow: auto; }
      .post-playlist .playlist-chapters .playlist-index ul li {
        display: none; }
        .post-playlist .playlist-chapters .playlist-index ul li.is-current {
          display: block; }
        .post-playlist .playlist-chapters .playlist-index ul li a {
          margin: 0; }
          .post-playlist .playlist-chapters .playlist-index ul li a:before {
            display: none; }
          .post-playlist .playlist-chapters .playlist-index ul li a:hover, .post-playlist .playlist-chapters .playlist-index ul li a:focus {
            background: transparent; }
          .post-playlist .playlist-chapters .playlist-index ul li a .tooltip {
            display: none; }
          .post-playlist .playlist-chapters .playlist-index ul li a .index-count {
            font-family: "National 2 Condensed";
            font-size: 14px;
            font-weight: 900;
            display: inline-block;
            width: 28px;
            height: 28px;
            color: #fff;
            line-height: 28px;
            border-radius: 50%;
            background-color: #1a17af;
            text-align: center;
            margin-right: 13px; }
    .post-playlist .playlist-chapters .hamburger {
      margin: 0;
      background-color: #fff;
      width: 45px;
      text-align: center;
      width: 45px;
      text-align: center;
      height: 21px;
      top: -21px;
      left: 50%;
      transform: translateX(-50%);
      border: 1px solid #e6e6e6;
      border-top-right-radius: 5px;
      border-top-left-radius: 7px;
      border-bottom: none;
      position: absolute; }
      .post-playlist .playlist-chapters .hamburger .menu-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 2px; }
        .post-playlist .playlist-chapters .hamburger .menu-btn .bar {
          background-color: #b3b3b3;
          height: 1px;
          margin: 4px 0;
          width: 22px; }
          .post-playlist .playlist-chapters .hamburger .menu-btn .bar:first-child {
            margin-top: 0; }
          .post-playlist .playlist-chapters .hamburger .menu-btn .bar:last-child {
            margin-bottom: 0; }
    .post-playlist .playlist-chapters .playlist-back-btn,
    .post-playlist .playlist-chapters .playlist-next-btn {
      position: absolute;
      height: 100%;
      display: flex;
      align-items: center; }
    .post-playlist .playlist-chapters .playlist-next-btn {
      right: 0;
      padding-right: 15px; }
    .post-playlist .playlist-chapters .playlist-back-btn {
      left: 0;
      padding-left: 15px; }
    .post-playlist .playlist-chapters li {
      text-align: center;
      margin: 0;
      padding: 0; } }

@media only screen and (max-width: 480px) {
  .post-playlist .cover {
    min-height: 210px;
    padding-top: 0; }
    .post-playlist .cover .title {
      font-family: "Tiempos Fine";
      font-weight: 300;
      font-size: 34px;
      line-height: 40px;
      padding: 0; }
    .post-playlist .cover .desc {
      font-family: "National 2";
      font-size: 13px;
      line-height: 22px;
      padding: 0;
      margin-top: 3px; }
  .post-playlist-content .cover-block {
    padding-left: 15px;
    padding-right: 15px; }
    .post-playlist-content .cover-block .cover-block-text {
      font-size: 20px;
      line-height: 24px; } }

span.s-arrow {
  display: block;
  width: 15px;
  height: 15px;
  border: 3px solid #cccccc;
  border-left: 0;
  border-bottom: 0; }
  span.s-arrow.arrow-next {
    transform: rotate(45deg); }
  span.s-arrow.arrow-back {
    transform: rotate(-135deg); }

@import "variables";


.wp-block-gallery{
    display: grid;
    &.columns-2{grid-template-columns: 50% 50%;}
    &.columns-3{grid-template-columns: 33.33% 33.33% 33.33%;}
    &.columns-4{grid-template-columns: 25% 25% 25% 25%;}
    &.columns-5{grid-template-columns: 20% 20% 20% 20% 20%;}
    &.columns-6{grid-template-columns: 16.66% 16.66% 16.66% 16.66% 16.66% 16.66%;}
    &.columns-7{grid-template-columns: 14.28% 14.28% 14.28% 14.28% 14.28% 14.28% 14.28%;}
    &.columns-8{grid-template-columns: 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5%;}
    list-style: none;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    overflow: hidden;
	.blocks-gallery-item{
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
		figure{
            position: relative;
			figcaption{
				position: absolute;
                bottom: 0;
                min-height: 30px;
                line-height: 15px;
                padding: 5px 10px;
                width: 100%;
                height: 100%;
                left: 0; top: 0;
                color: #fff;
                box-sizing: border-box;
                font-size: 13px;
                font-family: $font-3;
                font-weight: 400;
                display: flex;
                align-items: center;
                justify-content: center;
                a{
                    color: #FFF;
                    padding: 0;
                    border-bottom: none;
                    text-decoration: underline;
                    opacity: 0;
                    position: absolute;
                    width: 100%; height: 100%;
                    left: 0; top: 0;
                    &:hover{
                        text-decoration: none;
                    }
                }
			}
		}
	}
}
.wp-block-columns ,
.wp-block-text-columns {
    display: flex;
    margin:0 -10px;
    // margin: 20px 0;
}
.wp-block-column {
    flex: 1;
    padding:0 10px;
}
.wp-block-cover{
    height: 500px;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
	.wp-block-cover-text{
		color:#fff;
		position: absolute;
		top:50%;
		left: 50%;
		width: 100%;
		// height: 100%;
		text-align: center;
		transform:translate(-50% , -50%);
		font-size: 28px;
    	line-height: 40px;
	}
}
.wp-block-verse,
.wp-block-preformatted{
	word-wrap: break-word;
	overflow: hidden;
	line-height: 20px;
}
.wp-block-image.alignfull{
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    overflow: hidden;
    padding: 0;
    // height: 0;
    // padding-bottom: 46.3%;
}
.default-css{
    .resource-link{
        a{
            text-decoration: none;
            border-bottom: none;
        }
    }
}
.block-resources-links{
    background-color: #f8f8f8;
    padding: 20px;
    margin: 50px 0;
    border-radius: 5px;
    .resource-link{
        a{
            display: inline-flex;
        }
        .pdf-thumb{
            img{
                display: block;
            }
        }
        .pdf-detail{
            padding-left: 20px;
            color: $color-5;
            max-width: 50%;
            .icon-pdf{
                width: 50px;
                display: block;
            }
            label{
                cursor: pointer;
            }
        }
        
    }
}
.accordion{
    perspective: none;
    > *{
        perspective: none;
        margin-bottom: 20px;
        > :first-child{
            outline: none;
            line-height: 1;
            color: $color-5;
            padding: 0;
            margin: 0 0 15px;
            display: flex;
            .first-child{
                width: 25px;
                margin-right: 15px;
                font-weight: 700;
            }
        }
        > :last-child{
            padding-bottom: 20px;
            padding-left: 40px;
        }
    }
    .ico-plus{
        display: block;
        color: #e52c5b;
    }
    .ico-minus{
        display: none;
        color: #e52c5b;
    }
    .open{
        .ico-plus{
            display: none;
        }
        .ico-minus{
            display: block;
        }
    }
}


@media only screen and (max-width : 768px) {
    
	.wp-block-gallery{
		grid-template-columns: 50% 50%;
	}
}

@media only screen and (max-width : 650px) {
	.wp-block-columns ,
	.wp-block-text-columns{
		flex-direction: column;
    }
    
    .block-resources-links .resource-link .pdf-detail{
        font-size: 14px;
        line-height: 18px;
        .icon-pdf{
            width: 50px;
        }
    }
}
$font-1 : 'National 2'; // 300, 800
$font-2 : 'National 2 Condensed'; //800
$font-3 : 'Tiempos Text'; //300, 400, 400i, 600, 600i
$font-4 : 'Tiempos Fine'; //300
$font-5 : "National 2";
$pitchSans : 'Pitch Sans';//300


$color-5 :#1a17af;
$color-10 :#585b60;
$color-15 :#e52c5a;
$color-20: #e6e6e6;
$color-25 : #1e1e1e;
$color-30 : #c9cdd4;
$color-40: #999999;

$slideSpeed : 1s;
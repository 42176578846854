.menu-btn{
	// position: absolute;
	display: inline-block;
	cursor: pointer;
	.bar {
	  width: 28px;
	  height: 3px;
	  margin: 6px 0;
	  transition: 0.4s;
	}
	
}

.close{

	padding: 20px;
	// background: red;
	position: relative;
	top: -20px;
	right: -20px;

	.bar1 {
		transform: rotate(-45deg) translate(0, 0);
	}
	.bar2 {
		opacity: 0;
	}
	.bar3 {
		transform: rotate(45deg) translate(-13px, -13px);
	}
}
// https://gist.github.com/jonathantneal/d0460e5c2d5d7f9bc5e6
@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}
@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

@include font-face("Tiempos Fine", "../fonts/TiemposFineWeb-Light", 300, normal, woff woff2);

@include font-face("Tiempos Text", "../fonts/TiemposTextWeb-RegularItalic", 400, italic, woff woff2);
@include font-face("Tiempos Text", "../fonts/TiemposTextWeb-Regular", 400, normal, woff woff2);
// @include font-face("Tiempos Text", "../fonts/TiemposTextWeb-Bold", 600, italic, woff woff2); //Semibold italic not provided
@include font-face("Tiempos Text", "../fonts/TiemposTextWeb-Bold", 600, normal, woff woff2); //Semibold not provided

// @include font-face("National 2", "../fonts/National2Web-Regular", 300, normal, woff woff2); //Light not provided
@include font-face("National 2", "../fonts/National2Web-Regular", 400, normal, woff woff2);
@include font-face("National 2", "../fonts/National2Web-Extrabold", 800, normal, woff woff2);

@include font-face("National 2 Condensed", "../fonts/National2CondensedWeb-Extrabold", 800, normal, woff woff2);

@include font-face("Pitch Sans", "../fonts/PitchSansWeb-Light", 300, normal, woff woff2);


// @font-face {
//     font-family: 'Tiempos Fine Test';
//     src: url('../fonts/TiemposFineTest-Light.eot');
//     src: url('../fonts/TiemposFineTest-Light.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/TiemposFineTest-Light.woff') format('woff'),
//         url('../fonts/TiemposFineTest-Light.ttf') format('truetype'),
//         url('../fonts/TiemposFineTest-Light.svg#TiemposFineTest-Light') format('svg');
//     font-weight: 300;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Tiempos Text';
//     src: url('../fonts/TiemposText-RegularItalic.eot');
//     src: url('../fonts/TiemposText-RegularItalic.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/TiemposText-RegularItalic.woff') format('woff'),
//         url('../fonts/TiemposText-RegularItalic.ttf') format('truetype'),
//         url('../fonts/TiemposText-RegularItalic.svg#TiemposText-RegularItalic') format('svg');
//     font-weight: normal;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Tiempos Text';
//     src: url('../fonts/TiemposText-Regular.eot');
//     src: url('../fonts/TiemposText-Regular.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/TiemposText-Regular.woff') format('woff'),
//         url('../fonts/TiemposText-Regular.ttf') format('truetype'),
//         url('../fonts/TiemposText-Regular.svg#TiemposText-Regular') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Tiempos Text';
//     src: url('../fonts/TiemposText-SemiboldItalic.eot');
//     src: url('../fonts/TiemposText-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/TiemposText-SemiboldItalic.woff') format('woff'),
//         url('../fonts/TiemposText-SemiboldItalic.ttf') format('truetype'),
//         url('../fonts/TiemposText-SemiboldItalic.svg#TiemposText-SemiboldItalic') format('svg');
//     font-weight: 600;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Tiempos Text';
//     src: url('../fonts/TiemposText-Semibold.eot');
//     src: url('../fonts/TiemposText-Semibold.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/TiemposText-Semibold.woff') format('woff'),
//         url('../fonts/TiemposText-Semibold.ttf') format('truetype'),
//         url('../fonts/TiemposText-Semibold.svg#TiemposText-Semibold') format('svg');
//     font-weight: 600;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'National 2 Test';
//     src: url('../fonts/National2Test-Light.eot');
//     src: url('../fonts/National2Test-Light.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/National2Test-Light.woff') format('woff'),
//         url('../fonts/National2Test-Light.ttf') format('truetype'),
//         url('../fonts/National2Test-Light.svg#National2Test-Light') format('svg');
//     font-weight: 300;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'National 2 Tests';
//     src: url('../fonts/National2Test-Regular.eot');
//     src: url('../fonts/National2Test-Regular.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/National2Test-Regular.woff') format('woff'),
//         url('../fonts/National2Test-Regular.ttf') format('truetype'),
//         url('../fonts/National2Test-Regular.svg#National2Test-Regular') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'National 2 Test';
//     src: url('../fonts/National2Test-Extrabold.eot');
//     src: url('../fonts/National2Test-Extrabold.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/National2Test-Extrabold.woff') format('woff'),
//         url('../fonts/National2Test-Extrabold.ttf') format('truetype'),
//         url('../fonts/National2Test-Extrabold.svg#National2Test-Extrabold') format('svg');
//     font-weight: 800;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'National 2 Condensed Test';
//     src: url('../fonts/National2CondensedTest-Extrabold.eot');
//     src: url('../fonts/National2CondensedTest-Extrabold.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/National2CondensedTest-Extrabold.woff') format('woff'),
//         url('../fonts/National2CondensedTest-Extrabold.ttf') format('truetype'),
//         url('../fonts/National2CondensedTest-Extrabold.svg#National2CondensedTest-Extrabold') format('svg');
//     font-weight: 800;
//     font-style: normal;
// }
.arrow-container{
  position: fixed;
  width: 100%;
  bottom: 0;
  &.reverse{
    transform: rotate(180deg);
    top: 0;
    bottom: auto;
    z-index: 999;
    display: none;
    .arrow{
      padding: 5px 0;
    }
  }
}
.arrow{
   text-align: center;
   padding: 40px 0;
    svg{
        cursor: pointer;
        width: 60px;
        height: auto;
        // fill: #585b60;
        fill: #FFF;
    }
}
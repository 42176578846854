@import 'variables';
.cover{
	height: 568px;
	color:#fff;
	&.bg-image{
		display: block;
		height: 0;
		padding-bottom: 46.3%;
		background-color: #aaa;
		position: relative;
		.cover-content{
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			position: absolute;
			width: 100%;
		}
	}
	&.solid{
		height: 442px;
		.title{
			color:$color-5;
		}
		.desc{
			color:$color-10;
		}
	}
	.cover-content{
		text-align: center;
	}
	.title{
		font-family: $font-2;
		font-size: 96px;
		line-height: 100px;
		max-width: 1000px;
		padding: 15px;
		font-weight: 800;
		text-transform: uppercase;
		margin: auto;
	}
	.desc{
		// font-family: $font-3;
		font-size: 21px;
		line-height: 25px;
		max-width: 708px;
    	margin: auto;
    	margin-top: 13px;
	}
}


@media only screen and (max-width : 1400px) {
	figcaption{
		padding: 0 15px;
	}
	.size-small figcaption{
		padding: 0;
	}
}

@media only screen and (max-width : 992px) {
	.cover{ 
		.title{
			font-size: 60px;
	   		line-height: 65px;
		}
	}	
}
@media only screen and (max-width : 768px) {
	.cover{
		// min-height: 335px !important;
		// height: auto !important; 
	   	// padding: 70px 0 20px 0;
	   	&.solid{
	   		min-height: 200px !important;
	   		padding-top: 20px;
	   		padding-bottom: 20px;
			height: auto;
		}
		.title{
			font-size: 45px;
	   		line-height: 46px;
		}
		.desc{
			margin-top: 0;
		}
	}	
}


@media only screen and (max-width : 480px) {
	.cover{
	   	&.solid{
		    min-height: 150px !important;
		    padding-top: 20px;
	   	}
		.title{
			// font-family: $font-4;
			font-weight: 300;
			font-size: 24px;
	   		line-height: 26px;
	   		padding:0;
		}
		.desc{
			font-family: $font-1;
			line-height: 22px;
			padding: 0;
			margin-top: 3px;
		}
	}
}
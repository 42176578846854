.lightbox{
    position: fixed;
    background: rgba(#eee, 0.9);
    width: 100%;
    height: 100%;
    overflow:hidden;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.lightbox-inner{
    // width: 100%;
    max-width: 1000px;
    max-height: 100%;
    overflow-y: auto;
    padding: 10px;
    margin: 10px;
    background: #FFF;
    position: relative;
    .close{
        position: absolute;
        top: 0;
        right: 0;
        color: #1a17af;
        font-weight: 700;
        cursor: pointer;
        background: #fff;
        padding: 10px;
        z-index: 10;
    }
    img{
        display: block;
        max-width: 100%;
        max-height: 100%;
    }
    .video-container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px; height: 0; overflow: hidden;
        width: 800px;
        iframe,
        object,
        embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .social-share{
        background-color: #FFF;
        top: 0; bottom: 0;
        margin: auto;
    }
}
.masonry-container{
	max-width: 1440px;
	padding: 0 20px;
	margin: 0 auto;
}
.masonry{
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px;
	> div{
		height: 0;
		padding-bottom: 56.5%;
		background: #eee; 
		grid-row: span 1;
		position: relative;
	}
	div.v{
		//width: 370px; 
		//height: 430px; 
		padding-bottom: 117.3%;
		grid-row: span 2;
	}
	.grid-content{
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		>img{
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: absolute;
		}
		.play{
			position: absolute;
			z-index: 99;
			left: 0; right: 0;
			top: 0; bottom: 0;
			margin: auto;
			width: 80px;
			height: 80px;
			pointer-events: none;
			svg{
				fill: rgba(#FFF, 0.8);
				width: 100%;
				height: 100%;
				transition: fill 0.35s;
				pointer-events: none;
			}
		}
		&:hover{
			.play{
				svg{
					fill: #1a17af;
				}
			}
		}
	}
	.content-center{
		width: 100%;
		position: absolute;
		transform: translate(-50%, -50%);
		top: 50%;
		left: 50%;
		color: #FFF;
	}
	.social-share{
		background-color: #FFF;
		transform: translateY(-50%);
		top: 50%;
		height: 160px;
		width: 45px;
		border-top-left-radius: 15px;
    	border-bottom-left-radius: 15px;
		.icons{
			a.fb{
				margin-bottom: 16px;
			}
			a.twitter{
				margin-bottom: 14px;
			}
		}
	}
	img{
		display: block;
	}
}

.share-filters{
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	margin: 50px 0;
	> div{
		width: 20%;
		padding-right: 20px;
		&:last-child{
			padding-right: 0;
		}
		label{
			display: block;
			margin-bottom: 4px;
			font-family: "National 2";
		}
		select{
			width: 100%;
			padding: 4px;
		}
	}
}

ul.pagination{
    list-style: none;
	display: flex;
	margin: 30px 0 10px;
    li{
		padding: 10px;
		background: #EEE;
		margin-right: 10px;
		cursor: pointer;
		a{
			color: #1a17af
		}
		&.active, &:hover{
			background: #1a17af;
			a{
				color: #FFF;
			}
		}
    }
}


@media only screen and (max-width : 992px) {
	.masonry{
		grid-template-columns: 1fr 1fr;
		.social-share{
			height: 110px;
    		width: 35px;
			.icons a.fb{margin-bottom: 5px;}
			.icons a.fb svg{ width: 20px; height: 28px;}
			.icons a.twitter{margin-bottom: 7px;}
			.icons a.twitter svg{width: 16px; height: 18px;}
			a.mail svg{width: 16px; height: 16px;}
		}
		.grid-content{
			.play{
				width: 50px;
			}
		} 
	}
}

@media only screen and (max-width : 600px) {
	.masonry{
		grid-template-columns: 1fr;
	}
	.share-filters{
		margin-bottom: 20px;
		> div{
			width: 50%;
			margin-bottom: 20px;
		}
	}
}
@import 'variables';

a.full-background{
	// display: block;
	height: 509px;
}
.grid-container{
	// margin: 0 -5px;
	max-width: 1400px;
	margin:auto;
}
.grid{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.grid-item{
		width:50%;
		margin:8px 0;
		&:nth-child(even){
			padding-left:8px;
		}
		&:nth-child(odd){
			padding-right:8px;
		}
		.post-title{
			font-size: 46px;
			font-weight: 800;
			text-transform: capitalize;
			line-height: 48px;
			max-width: 446px;
			margin:auto;
		}
		.post-desc{
			line-height: 22px;
			max-width: 417px;
			margin:auto;
			color:$color-20;
			margin-top: 17px;
			margin-bottom: 15px;
		}
		a{
			color:#fff;
			background-color: #aaa;
			.btn{
				outline: none;
				cursor: pointer;
			}
			&:hover{
				.btn{
					background-color: $color-5;
					&.blue{
						background-color: rgba($color-25, 1)!important;
					}
				}
			}
		}
	}
}

@media only screen and (max-width : 992px) {
	.grid .grid-item{ 
		a{
			&.full-background{
				height: 400px;
			}	
		}
		
		.post-title{
			font-size: 40px;
			line-height: 40px;
			padding:0 10px;
		}
	}	
}

@media only screen and (max-width : 768px) {
	.grid .grid-item{
		width: 100%;
		padding:0 !important;
	}
}	
@import "variables";
.site-blog{
	.container{
		max-width: 1200px;
	}
}
.sidebars-container{
	display: flex;
	margin-top: 60px;
	.blog-posts{
		width: 70%;
		padding-right: 50px;
	}
	.sidebar-widgets-container{
		width: 30%;
	}
}

.blog-post-header{ 
	.name{
		font-size: 40px;
	    line-height: 40px;
	    a{
			border-bottom: 0;
			color: $color-5;
	    	&:hover{
				text-decoration: underline;
	    	}
	    }
	}
	.meta{
		margin: 10px 0;
	}
}
.blog-post{
	margin-bottom: 40px;
	.excerpt{
		p{
			display: inline;
		}
	}
}

.widget{
	h2.name{
		font-size: 44px;
    	line-height: 53px;
	}
}

.paging{
	display: flex;
	a{
		display: block;
		margin: 5px 6px 0;
		color: $color-5;
		width: 20px;
		text-align: center;
		&:first-child{
			margin-left: 0;
		}
	}
}
@media (max-width: 768px) { 
	.sidebars-container{
		flex-direction: column;
		.blog-posts{
			width: 100%;
			padding-right: 0;
		}
		.sidebar-widgets-container{
			width: 100%;
		}
	}
}

@media only screen and (max-width: 480px){
	.blog-post-header{
		.name{
			font-size: 40px !important;
    		line-height: 42px !important;
		}
	}
}
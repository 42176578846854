@import "variables";

.site-footer-container{
	position: relative;
	padding-top: 50px;
	.hidden{
		display: none;
	}
	.show{
		display: block;
	}
	> .container{
		padding:0;
	}
}
.footer-nav-bar{
	display: flex;
	justify-content: space-between;
	margin-bottom: 3px;
	a{
		font-family: $font-2;
		font-size: 19px;
		display: block;
		color:#fff;
		height: 26px;
		text-align: center;
		background-color: $color-30;
		width: 16.5%;
		line-height: 30px;
		text-transform: uppercase;
		transition: background-color .5s;
		&:hover , &.active{
			background-color: $color-5;
		}
	}
}


.site-footer-content{
	display: flex;
    justify-content: space-between;
    align-items: center;
	// height: 100%;
	height: 72px;
	padding: 0;
}

.site-footer{
	background-color: $color-25;
	.container{
		padding:0 35px;
	}
    .footer-navigation{
    	a{
    		font-size: 14px;
    		color: $color-40;
    		transition: color .5s;
    		display: inline-block;
    		position: relative;
    		&:after{
				content: '|';
				display: inline-block;
				color:$color-40;
				margin: 0 13px;
    		}
    		&:last-child{
    			&:after{
    				display: none;
    			}
    		}
    		&:hover{
    			color:$color-5;
    		}
    	}
    }
}

.social-media-and-newsletter{
	align-items: center;
	display: flex;
	align-items: center;
	height: 100%;
	.social-media{
		height: 100%;
		margin-right: 40px;
	}
	.social-media-icons{
		height: 100%;
		a{
			display: inline-block;
			height: 100%;
			width: 72px;
			position: relative;
			border-right: 4px solid;
			border-color:#fff;
			&:first-child{
				border-left: 4px solid;
				border-color: #fff;
			}
			// text-align: center;
			span{
				display: inline-block;
				position: absolute;
				top:50%;
				left: 50%;
				transform:translate(-50% , -50%);
				&.facebook svg{
					width: 12px;
	    			height: 25px;
				}
				&.twitter svg{
					width: 25px;
					height: 20px;
				}
			}
			svg{
				transition: fill .5s;
				fill:#fff;
			}
			&:hover{
				svg{
					fill:$color-5;
				}
			}
		}
	}
	.newsletter-button{
		a{
			color:#fff;
			font-size: 25px;
    		text-transform: uppercase;
    		display: block;
    		position: relative;
    		&:after{
    			content: '';
    			width: 10px;
    			height: 10px;
    			display: inline-block;
    			border: 4px solid #fff;
			    border-left: 0;
			    border-bottom: 0;
			    transform: rotate(45deg);
			    position: relative;
			    top: -1px;
			    margin-left: 5px;
    		}

    		&:hover{
    			color:$color-5;
    			&:after{
    				border-color:$color-5;
    			}
    		}
		}	
	}
	.donation{margin-right: 35px;}
}

::selection { background: #1e1e1e; color: #FFF; }

@media only screen and (max-width : 1080px) {
	.site-footer .container{
		padding:0 !important;
	}
	.site-footer-content{
		height: auto;
		flex-direction: column;
		.footer-navigation-container{
			min-height: 38px;
			.footer-navigation{
				height: 100%;
				a{
					line-height: 38px;
				}
			}
		}
		.social-media-and-newsletter{
			width: 100%;
			height: 56px;
			border-top:4px solid #fff;
			justify-content: space-between;
			padding-right: 20px;
			.social-media-icons a:first-child{
				border-left:none;
			}
		}
	}

	.footer-nav-bar{
		display: none;
	}
}

@media only screen and (max-width : 480px) {
	.site-footer .footer-navigation{
        padding: 0 15px;
        a{
			font-size: 12px;
			&:after{
				margin: 0 13px;
			}
        }
    } 
	.social-media-and-newsletter .social-media{
		margin-right: 0;
	}
	.social-media-and-newsletter .social-media-icons a{
		width: 40px;
	}
	.social-media-and-newsletter .donation{margin-right: 0;}
	.social-media-and-newsletter .newsletter-button a{
		font-size: 16px;
		&:after{
			width: 5px;
    		height: 5px;
    		top: 0;
		}
	}
}
@import "variables";

.site-header{
    position: fixed;
    top: 0; left: 0;
    width: 100%;
    z-index: 999;
    transition: background-color 0.5s;
    .container{
        padding: 25px 38px;
        display: flex;
	    flex-direction: row;
        justify-content: space-between;
        transition: padding 0.35s;
    }
    &.blue{
        .site-logo{
            img.blue{
                display: block;
            }
            img.white{
                display: none;
            }
        }
        .menu-btn{
            .bar{
                background-color: $color-5;
            }
        }
    }
    &.white{
        .site-logo{
            img.blue{
                display: none;
            }
            img.white{
                display: block;
            }
        }
        .menu-btn{
            .bar{
                background-color: #FFF;
            }
        }
    }
}
.header-right{
    display: flex;
    align-items: flex-start;
    .back-to-explore{
        color: #FFF;
        margin-top: 0;
        display: flex;
        align-items: center;
        align-content: center;
        font-size: 16px;
        font-weight: 400;
        margin-right: 50px;
        svg{
            fill: #FFF;
            width: 43px;
            height: 26px;
            margin-right: 20px;
        }
    }
}
.site-logo{
    a{
        display: block;
        width: 50px;
    }
    img{
        display: block;
        width: 100%;
        // width: 50px;
    }
}
.page-scrolled{
    .site-header{
        background-color: rgba(#FFF, 0.8);
        .container{
            // padding: 10px 38px;
            padding:10px 15px;
        }
        .site-logo{
            .blue{
                display: block!important;
            }
            .white{
                display: none!important;
            }
            img{
                width: 35px;
            }
        }
        .menu-btn{
            .bar{
                background-color: $color-5!important;
            }
        }
        .back-to-explore{
            color: $color-5;
            svg{
                fill: $color-5;
            }
        }
    }
}


@media only screen and (max-width : 768px) {
    .site-header .container{
        padding: 8px;
    }
    .site-logo{
        a{
            width: 33px;
        }    
    }
}
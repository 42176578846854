@charset "UTF-8";
.post-meta {
  color: #e52c5b;
  border: 1px solid #1a17af;
  border-left: 0;
  border-right: 0;
  padding: 2px 0;
  font-family: "Pitch Sans";
  font-size: 15px;
  margin-bottom: 40px; }
  .post-meta .post-date {
    display: inline-block;
    position: relative;
    padding-left: 20px;
    margin-left: 20px; }
    .post-meta .post-date:before {
      content: '';
      display: inline-block;
      width: 1px;
      height: 60%;
      background-color: #1a17af;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0; }

.default-post {
  max-width: 800px;
  margin: 100px auto; }

.default-css {
  color: #585b60;
  font-size: 16px;
  line-height: 27px; }
  .default-css h1 {
    color: #1a17af;
    font-size: 52px;
    line-height: 61px; }
  .default-css h2 {
    color: #1a17af;
    font-size: 52px;
    line-height: 61px; }
  .default-css h3 {
    color: #1e1e1e;
    font-size: 42px;
    line-height: 49px;
    margin-bottom: 10px; }
  .default-css p {
    font-family: "Tiempos Text";
    color: #585b60;
    font-size: inherit;
    line-height: inherit;
    margin-bottom: 20px; }
  .default-css ul, .default-css ol {
    font-size: inherit;
    line-height: inherit;
    list-style-position: outside;
    margin: 0 0 30px 15px; }
  .default-css a {
    color: #000;
    border-bottom: 1px solid #e52c5b;
    padding-bottom: 3px;
    transition: color 0.35s; }
    .default-css a:hover {
      border-bottom-color: transparent; }
  .default-css blockquote {
    color: #e52c5b;
    font-size: 31px;
    line-height: 39px;
    margin-bottom: 28px; }
    .default-css blockquote p {
      color: inherit;
      font-size: inherit;
      line-height: inherit; }
  .default-css img {
    max-width: 100%;
    height: auto; }
  .default-css select, .default-css textarea,
  .default-css input[type="text"],
  .default-css input[type="email"] {
    border-radius: 3px;
    background: #FFF;
    border: 1px solid #c9cdd4;
    font-family: "Tiempos Text";
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    padding: 5px 10px;
    color: #1e1e1e;
    outline: none; }
  .default-css textarea {
    line-height: 20px;
    height: auto; }
  .default-css table {
    width: 100%; }
    .default-css table tr {
      border: 1px solid black; }
      .default-css table tr td {
        padding: 10px;
        border-left: 1px solid; }
  .default-css hr {
    background-color: #1a17af;
    height: 1px;
    border: none; }
  .default-css .mb0 {
    margin-bottom: 0; }
  .default-css .mb10 {
    margin-bottom: 10px; }
  .default-css .mb20 {
    margin-bottom: 20px; }
  .default-css .noborder {
    border: none !important; }
  .default-css .wp-caption {
    max-width: 100%; }
    .default-css .wp-caption .wp-caption-text {
      font-size: 12px;
      line-height: 16px;
      font-family: "Pitch Sans";
      font-weight: 300;
      margin: 0; }
    .default-css .wp-caption.moveleft {
      position: relative;
      left: -30px;
      margin-right: 0 !important; }
      .default-css .wp-caption.moveleft img {
        width: 100%; }
    .default-css .wp-caption.aligncenter {
      margin: 10px auto; }
    .default-css .wp-caption.alignleft {
      margin: 10px 10px 10px 0; }
    .default-css .wp-caption.alignright {
      margin: 10px 0 10px 20px; }
    .default-css .wp-caption.alignnone {
      margin: 10px auto; }
  .default-css .alignleft {
    float: left;
    margin: 5px 10px 0 0; }
  .default-css .alignright {
    float: right;
    margin: 5px 0 0 10px; }
  .default-css .aligncenter {
    text-align: center; }

.mobile-only {
  display: none; }

.blue-color {
  color: #1a17af; }

.green-color {
  color: #0fa388; }

.join-campaign {
  margin-bottom: 80px; }
  .join-campaign a {
    font-size: 18px; }
  .join-campaign p {
    margin-bottom: 10px; }

input[type="submit"] {
  background: none;
  border: none;
  font-weight: 700;
  width: 150px;
  height: 40px;
  line-height: 40px;
  background-color: #1a17af;
  color: #fff;
  transition: all .5s;
  cursor: pointer;
  outline: none; }
  input[type="submit"]:hover {
    background-color: #e52c5a; }

.contcat-us-form {
  position: relative; }
  .contcat-us-form select, .contcat-us-form textarea,
  .contcat-us-form input[type="text"],
  .contcat-us-form input[type="email"] {
    width: 100%; }
  .contcat-us-form:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden; }
  .contcat-us-form.form-being-submited:after {
    opacity: 1;
    visibility: visible; }

.show_form_message {
  display: block; }

.hide_form_message {
  display: none; }

.validation_failed, .mail_failed {
  color: red; }

.mail_sent {
  color: green; }

.blog-post .default-css {
  font-size: 17px; }

.blog-post .cover {
  height: auto;
  padding-top: 100px;
  margin-bottom: 30px; }
  .blog-post .cover .container {
    max-width: 860px; }
  .blog-post .cover .title {
    font-family: "Tiempos Fine";
    text-align: left;
    text-transform: unset;
    font-weight: 300; }

.blog-post .default-css.default-post {
  margin-top: 0; }

.blog-post .alignleft {
  position: relative; }
  .blog-post .alignleft:after {
    content: '';
    display: block;
    clear: both; }

.blog-post .default-css, .blog-post .default-css p {
  color: #1a17af; }

.blog-post figcaption,
.blog-post .default-css .wp-caption-text {
  color: #e52c5a; }

.blog-post .default-post > div {
  clear: both; }

.blog-post .default-post a {
  color: #1a17af; }

.blog-post .default-post img {
  width: 100%;
  max-width: unset; }

.blog-post .default-post iframe {
  width: 100%; }

.adjacent-posts-container {
  display: flex;
  padding: 10px 0;
  border-top: 1px solid #1a17af;
  margin-top: 50px; }
  .adjacent-posts-container .adj-post {
    width: 50%;
    position: relative; }
    .adjacent-posts-container .adj-post:nth-child(2) {
      position: relative; }
      .adjacent-posts-container .adj-post:nth-child(2):before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        height: 100%;
        width: 1px;
        background-color: #1a17af; }
    .adjacent-posts-container .adj-post:nth-child(1) {
      text-align: left;
      padding-left: 30px;
      padding-right: 100px; }
    .adjacent-posts-container .adj-post:nth-child(2) {
      text-align: right;
      padding-right: 30px;
      padding-left: 100px; }
    .adjacent-posts-container .adj-post a {
      font-family: "Pitch Sans";
      text-decoration: none;
      color: #e52c5a;
      border-bottom: 0;
      max-height: 109px;
      overflow: hidden;
      display: inline-block;
      line-height: 21px; }
    .adjacent-posts-container .adj-post:hover a {
      color: #1a17af; }
    .adjacent-posts-container .adj-post.next-post:after {
      content: "»";
      display: inline-block;
      position: absolute;
      right: 0;
      color: #e52c5a;
      line-height: 18px; }
    .adjacent-posts-container .adj-post.next-post:hover:after {
      color: #1a17af; }
    .adjacent-posts-container .adj-post.prev-post:before {
      content: "«";
      position: absolute;
      display: inline-block;
      left: 0;
      color: #e52c5a;
      line-height: 18px; }
    .adjacent-posts-container .adj-post.prev-post:hover:before {
      color: #1a17af; }

@media only screen and (min-width: 992px) {
  .blog-post .cover {
    height: auto; }
    .blog-post .cover .title {
      font-size: 62px;
      line-height: 85px; } }

@media only screen and (max-width: 768px) {
  .mobile-only {
    display: block; }
  .desktop-only {
    display: none; }
  body .default-css {
    margin: 50px auto; }
    body .default-css h1 {
      font-size: 52px;
      line-height: 61px; }
    body .default-css h2 {
      font-size: 46px !important;
      line-height: 50px !important;
      margin-bottom: 10px; }
    body .default-css h3 {
      font-size: 30px;
      line-height: 34px; }
    body .default-css h4 {
      font-size: 35px;
      line-height: 42px; }
    body .default-css h5 {
      font-size: 32px;
      line-height: 36px; }
    body .default-css h6 {
      font-size: 29px;
      line-height: 35px; }
    body .default-css p {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 18px; }
    body .default-css .wp-caption.moveleft {
      left: 0;
      margin-right: 15px !important; }
  blockquote p {
    font-size: 20px !important;
    line-height: 25px !important; }
  .adjacent-posts-container .adj-post:nth-child(1) {
    padding-right: 30px; }
  .adjacent-posts-container .adj-post:nth-child(2) {
    padding-left: 30px; }
  .blog-post .cover {
    min-height: unset !important; }
    .blog-post .cover .title {
      padding-left: 0;
      padding-right: 0; } }

@media only screen and (max-width: 480px) {
  body .default-css h2 {
    font-size: 37px !important;
    line-height: 39px !important; }
  .blog-post .cover .title {
    font-size: 27px;
    line-height: 36px; }
  .blog-post figure.aligncenter img,
  .blog-post img.aligncenter {
    width: 100vw;
    margin-left: -15px; }
  .adjacent-posts-container {
    flex-direction: column; }
    .adjacent-posts-container .adj-post {
      width: 100%; }
      .adjacent-posts-container .adj-post:nth-child(2) {
        margin-top: 20px; }
        .adjacent-posts-container .adj-post:nth-child(2):before {
          display: none; } }

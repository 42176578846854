@import "./variables";

.nav-menu-container{
	z-index: 4;
	position: fixed;
	width: 100%;
	height: 100vh;
	top:0;
	left:0;
	background-color: #fff;
	z-index: 1000;
	.menu-btn{
		z-index: 999;
		position: absolute;
		top: 35px;
		right: 15px;
		.bar{
			background-color: #FFF;
		}
	}
	.nav-menus{
		width: 100%;
		height:100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.nav-item{
			display: block;
			width: 33.33%;
			height: 50%;
			.gutter{
				width: 100%;
				height: 100%;
				padding:2.5px;
			}
			.menu{
				height: 100%;
				position: relative;
			}
			.menu-title{
				width: 222px;
				height: 63px;
				background-color: #fff;
				display: inline-block;
				mix-blend-mode:lighten;
				position: absolute;
				top:50%;
				left:50%;
				transform: translate(-50% , -50%);
				text-align: center;
				font-family: $font-1;
				font-size: 25px;
			    font-weight: 700;
			    line-height: 63px;
			    text-transform: uppercase;
				transition: all .5s;
			}

			&:hover{ // .nav-item:hover
				.menu-title{
					background-color:black;
					mix-blend-mode:multiply;
					color:#fff;
				}
			}
		}
	}
}



.nav-menu-container-grid{
	z-index: 4;
	position: fixed;
	width: 100%;
	height: 100vh;
	top:0;
	left:0;
	background-color: #fff;
	z-index: 1000;
	
	&.fadeIn{
		display: flex;
		transition: height 0ms 0ms, opacity 600ms 0ms;
	}
	&.fadeOut{
		height: 0;
		opacity: 0;
		transition: height 0ms 400ms, opacity 400ms 0ms;
		overflow: hidden;
	}
	.menu-btn{
		z-index: 999;
		position: absolute;
		top: 35px;
		right: 15px;
		.bar{
			background-color: #FFF;
		}
	}
	.nav-menus{
		width: 100vw;
		height: 100vh;
		display: grid;
		grid-template-columns: 33.3333% 33.3333% 33.3333%;
		grid-template-rows: 33.3333% 33.3333% 33.3333%;
		grid-column-gap: 6px;
		grid-row-gap: 6px;
		.nav-item{
			background-position: left top;
			background-repeat: no-repeat;
			background-size: cover;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #000;
			.menu{
				height: 100%;
				position: relative;
			}
			span{
				display: block;
				width: 222px;
				height: 63px;
				color: $color-25;
				background-color: #fff;
				display: inline-block;
				mix-blend-mode: lighten;
				// position: absolute;
				// top:50%;
				// left:50%;
				// transform: translate(-50% , -50%);
				text-align: center;
				font-family: $font-1;
				font-size: 21px;
			    font-weight: 700;
			    line-height: 63px;
			    text-transform: uppercase;
				transition: all .5s;
			}

			&:hover{ // .nav-item:hover
				span{
					background-color: $color-5;
					mix-blend-mode: normal;
					color:#fff;
				}
			}
		}
	}
}

@media only screen and (max-width : 768px) {
	.nav-menu-container-grid .nav-menus {
		grid-template-columns: 50% 50%;
		grid-template-rows: 25% 25% 25% 25%;
	}
	.nav-menu-container-grid .menu-btn{
		top: 15px;
    	right: 10px;
	}
	.nav-menu-container-grid .nav-menus .nav-item span{
		max-width: 137px;
		width: 100%;
		height: 39px;
		font-size: 17px;
	    // font-weight: 700;
	    line-height: 42px;
	}
}
@import "variables";


.home{
    width: 100vw; 
    height: 100vh;
    position: relative;
    overflow: hidden;
    // .arrow{
    //     position: absolute;
    //     bottom: 0;
    //     left: 50%;
    //     transform: translateX(-50%);
    //     svg{
    //         fill: #FFF;
    //     }
    // }
    .menu-btn{
        // display: none;
    }
    .site-footer-container{
        // display: none;
        position: fixed;
        bottom: 0;
        width: 100%;
        .site-footer{
            // display: none;
        }
	}
}

.homehidden{
    height: 0;
    opacity: 0;
    padding: 0;
    transition: height 0ms 1000ms, opacity 1000ms 0ms;
}
.homeshow{
    opacity: 1;
	height: auto;
    transition: height 0ms 0ms, opacity 500ms 700ms;
}
.homepageslides{
    div.fullpageslide{
        height: 100vh;
        .bglayer{
            background-position: center center;
        }
        > div{
            width: 100vw;
            height: 100vh;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            background: no-repeat;
            background-size: cover;
        }
    }
}

.firstslide{
    width: 100vw; 
    height: 100vh;
    background-color: #E53A60;
    
    .hp-slide-container{
        position: relative;
        visibility: hidden;
        .hp-slide{
            position: absolute;
            width: 100%;
            height: 100vh;
            background-repeat: no-repeat;
            background-size: cover;
            color: #fff;
            background-position: center center;
            // span{
            //     display: inline-block;
            //     height: 50px;
            //     width: 50px;
            // }
            .slide-data{
                visibility: hidden;
                position: absolute;
                top: 60%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                transition: top 1s;
                width: 100%;
                max-width: 845px;
                padding: 15px;
                h2{
                    font-size: 82px;
                    margin-bottom: 15px;
                    text-transform: uppercase;
                }
                p{
                    font-family: $font-1;
                    font-size: 32px;
                    letter-spacing: -0.024em;
                    text-align: left;
                    line-height: 38px;
                    strong{
                        font-size: 36px;
                        letter-spacing: 0;
                    }
                }
            }
        }
        &:first-child{
            .hp-slide{
                .icon{
                    width: 62px;
                    display: block;
                    margin: 0 auto 17px;
                }
                p{
                    font-family: $font-3;
                    font-size: 19px;
                    line-height: 22px;
                    letter-spacing: -0.032em;
                    text-align: center;
                }
            }
            
        }
        &.active{
            visibility: visible;
            .slide-data{
                visibility: visible;
                top: 50%;
            }
        }
    }
    .site-footer{
        display:none;
    }
}

.fade-appear{
    opacity: 0;
    z-index: 1;
}
.fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 300ms linear
}
.fade-enter{
    opacity: 0;
    z-index: 1;
}
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms linear
}
.fade-exit{
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms linear
}
.fade-exit-done{
    opacity: 0;
}

.slick-vertical .slick-slide{
    border: 0;
}

.os-ios{
    .slide-video{
        // display : none;
    }
}


@media only screen and (min-width : 767px) and (max-height : 760px) {
    .firstslide .hp-slide-container .hp-slide .slide-data p{
        font-size: 25px;
        line-height: 30px;
    }
}

@media only screen and (min-width : 767px) and (max-height : 560px) {
    .firstslide .hp-slide-container .hp-slide .slide-data p{
        font-size: 15px;
        line-height: 20px;
    }
}

// http://browserhacks.com/#ch
@supports (-webkit-appearance:none) {
    .os-android, .os-ios{
        .firstslide .hp-slide-container.active .slide-data{
            top: 43%;
        }
        &.home{
            // height: calc(100vh - 56px);
            // overflow: hidden;
            .nav-menu-container-grid:not(.fadeOut){
                // height: calc(100vh - 56px);
                .nav-menus{
                    // height: 100%;
                }
            }
        }
    }
}
@media only screen and (max-width : 768px) {
    .home{
        .hp-slide-container{
            &:first-child .hp-slide .icon{
                width: 37px;
            }
            .hp-slide .slide-data {
                h2{
                    font-size: 73px;
                    line-height: 60px;
                    margin-bottom: 12px;
                }
                p{
                    font-size: 15px;
                    line-height: 20px;
                    letter-spacing: -0.034em;
                    strong{
                        font-size: 23px;
                    }
                }
            }    
        }
        .arrow{
            padding-bottom: 10px;
        }
        .menu-btn{
            display: block;
        } 
    } 
}

@media only screen and (max-width : 480px) {
    .home .hp-slide-container .hp-slide .slide-data h2 {
        font-size: 43px;
        line-height: 24px;
        // margin-bottom: 12px;
    }
}

.social-share{
	position: absolute;
	top: 200px;
	right: 0;
	height: 200px;
	width: 57px;
	// border: 1px solid black;
    border-right: 0;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    overflow: hidden;
	box-shadow: -2px 0 14px rgba(0,0,0,0.1);
	&.inline{
		position: static;
		display: flex;
		flex-direction: column;
		width: auto;
		border-radius: 0;
		box-shadow: none;
		height: auto;
		label{
			padding-left: 5px;
			margin-bottom: 2px;
			font-size: 14px;
		}
		.icons{
			flex-direction: row;
			align-items: baseline;
			justify-content: flex-start;
			a.fb{ 
				margin-bottom: 0; 
				margin-right: 8px; 
				position: relative;
				top: 2px;
				svg{
					width: 20px;
				}
			}
			a.twitter{ margin-bottom: 0; margin-right: 10px; }
			a.fb{ margin-bottom: 0;}
		}
	}
	.icons{
    	height: 100%;
    	width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
    	justify-content: center;
    	a{
    		display: inline-block;
    		svg{
    			fill:#b3b3b3;
    			transition: all .5s;
    		}
    		&.mail{
    			svg{
    				width: 28px;
    				height: 24px;
    			}
    		}
			
			&.twitter{
				margin-bottom: 25px;
				svg{
					height: 22px;
	    			width: 22px;
				}
			}
			
			&.fb{
				margin-bottom: 28px;
				svg{
					width: 30px;
				}
			}

			&:hover{
				svg{
					fill:#1a17af;
				}
			}
    	}

	}
}
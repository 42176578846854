@import "variables";
// .fade-exit-active .explore-menu{display: none;}
.explore{
    &:not(.explore-state){
        .content-container{
            background-color: #FFF;
        }
    }
    &.explore-state{
        .arrow-container{
            background-color: #f8f8f8;
        }
    }
    .cover-container{
        overflow: hidden;
    }
}
.markers{
    .closed{
        display: none;
    }
}
.marker{
    cursor: pointer;
    transform: translate(-10px, -28px);
}
.map-container{
    > svg{
        max-width: 1140px;
        height: auto;
        margin: 75px auto 95px;
        display: block;
        width: 100%;
        path{
            cursor: pointer;
            &:hover{
                fill: #e52c5a;
            }
        }
    }
}
.map-container-locations{
    > svg{
        > g{
            transition: transform 0.5s;
        }
        path{
            cursor: default;
            &:hover{
                fill: #c9cdd4; 
            }
            &.active-state{
                fill: #1a17af;
            }
        }
    }
    .map-meta img{
        margin: 0 auto 10px;
    }
}
.explore-menu{
    display: flex;
    margin-top: 107px;

    > a{
        background-color: #1e1e1e;
        color: #FFF;
        font-family: $font-2;
        font-weight: 800;
        text-align: center;
        font-size: 22px;
        line-height: 52px;
        padding: 0 5px;
        height: 52px;
        width: 33.3333%;
        border-right: 3px solid #FFF;
        &:last-child{
            border-right: none;
        }
        &:hover, &.active{
            background-color: $color-5;
        }
    }
}
.barchart-container{
    display: flex;
    justify-content: space-between;
}
.bar-chart{
    width: percentage(340/1050);
    display: flex;
    align-items: flex-end;
    .bar-container{
        width: 140px;
        &.confinement-bar{
            margin-right: 55px;
            .bar{
                background-color: #0f5246;
            }
            .cost{
                color: #0f5246;
            }
        }
        &.education-bar{
            .bar{
                background-color: #0fba9a;
            }
            .cost{
                color: #0fba9a;
            }
        }
    }
    .cost{
        font-size: 41px;
        line-height: 41px;
        font-family: $font-2;
        margin-bottom: 0;
        color: #0f5246;
    }
    .description{
        font-size: 12px;
        line-height: 14px;
        font-family: $font-3;
        font-weight: 400;
        font-style: italic;
    }
    .bar{
        width: 140px;
        border-radius: 12px 12px 0 0;
        margin: 0 0 10px;
        position: relative;
        &:after{
            content: '';
            position: absolute;
            bottom: 0; left: -30px;
            width: 200px;
            height: 2px;
        }
    }
}
.barchart-meta{
    width: percentage(460/1050);
}
.bar-disclaimer{
    color: #585b60;
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 30px;
}
.parents-pay{
    font-size: 19px;
    line-height: 27px;
    max-width: 400px;
    font-weight: 600;
    color: #1e1e1e;
    .icon-pay{
        display: block;
        margin-bottom: 10px;
    }
}
.map-detail{
    display: flex;
    max-width: 1150px;
    margin: auto;
    justify-content: space-between;
    .map-meta{
        width: percentage(307/1150);
    }
}
.map-text{
    width: percentage(800/1150);
    padding-right: 20px;
}
.map-meta{
    select{
        display: block;
        width: 100%;
        margin-bottom: 28px;
    }
    img{
        display: block;
    }
}
.gradient-container{
    display: block;
    width: 100%;
    // max-width: 307px;
    margin-bottom: 20px;
    .gradient-div{
        background: linear-gradient(to right, #56B5FD 0%, #2D0051 100%);
        height: 40px;
        border-radius: 5px;
        margin-bottom: 15px;
        &.green{
            background: linear-gradient(to right, rgba(15,186,154,1) 0%,rgba(15,82,70,1) 100%);
        }
    }
    .gradient-meta{
        display: flex;
        font-size: 14px;
        line-height: 16px;
        justify-content: space-between;
    }
}
.explore-state-container{
    .cover.solid{
        background: $color-5;
        .container{
            height: 445px;
            position: relative;
            display: flex;
            align-items: center;
        }
        .title{
            color: #FFF!important;
            text-transform: none;
            width: 100%;
            font-size: 82px;
            line-height: 85px;
            padding-left: 85px;
            text-align: left;
            z-index: 1;
        }
        .desc{
            position: absolute;
            right: 0;
            top: 50%;
            margin: auto;
            padding: 0;
            height: 100%;
            max-height: 400px;
            transform: translateY(-50%) rotate(-15deg);
            width: 100%;
            max-width: 450px;
            display: flex;
            img{
                width: 100%;
                max-width: 445px;
                height: auto;
                max-height: 400px;
            }
        }
    }
    .cover-content{
        width: 100%;
    }
}
.explore-section{
    padding: 155px 0;
    position: relative;
    .title{
        background: #000;
        color: #FFF;
        text-transform: uppercase;
        padding: 0 20px;
        line-height: 59px;
        font-size: 26px;
        display: inline-block;
        position: absolute;
        top: 0; left: 0;
        &:after{
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 59px 22px 0 0;
            border-color: #000 transparent transparent transparent;
            position: absolute;
            top: 0;
            right: -22px;
        }
    }
    .section-desc{
        font-size: 47px;
        line-height: 49px;
        max-width: 950px;
        margin-bottom: 65px;
    }
}
.explore-section-inner{
    max-width: 1160px;
    margin: auto;
}
.explore-racial, .explore-cost{
    .explore-section-inner-inner{
        max-width: 1050px;
    }
}
.stackedbar-container{
    > div{
        margin: auto;
        max-width: 900px;
        overflow: hidden;
    }
    svg{
        display: block;
        margin: auto;
    }
}
.chart-legend{
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    max-width: 500px;
    width: 100%;
    margin-top: 40px;
    label{
        margin-right: 10px;
        display: flex;
        align-items: baseline;
        white-space: nowrap;
        margin-bottom: 10px;
        &:last-child{
            margin-right: 0;
        }
    }
    span{
        width: 15px;
        height: 15px;
        display: block;
        margin-right: 5px;
    }
}
.pie-container{
    display: flex;
    justify-content: space-between;
    >div:first-child{
        width: percentage(560/1050);
    }
    >div:last-child{
        width: percentage(400/1050);
        .pie-chart-meta{
            >div{
                line-height: 36px;
                strong{
                    font-size: 30px;
                    width: 50px;
                    small{
                        bottom: 8px;
                        font-size: 15px;
                    }
                }
                span{
                    font-size: 15px;
                }
            }
        }
    }
    p{
        margin: 0 0 20px;
        font-size: 16px;
        line-height: 35px;
    }
}
.pie-chart{
    display: flex;
    margin-bottom: 10px;
    height: 315px;
    & + p{
        padding-left: 30px;
    }
}
.pie-chart-meta{

    > div{
        margin-bottom: 0;
        display: flex;
        line-height: 47px;
        strong{
            font-weight: 700;
            font-family: $font-2;
            font-size: 39px;
            width: 75px;
            text-align: right;
            small{
                font-family: $font-3;
                position: relative;
                bottom: 8px;
                left: 1px;
                font-weight: 400;
                font-size: 20px;
                line-height: inherit;
            }
        }
        span{
            color: #1E1E1E;
            font-size: 20px;
            line-height: inherit;
            white-space: nowrap;
        }
    }
}
.pie-svg{
    margin-right: 20px;
}
.explore-cost, .explore-resources, .explore-back{
    background: #f8f8f8;
}
span.sup{
    font-size: 60%;
    position: relative;
    top: 4px;
    vertical-align: super
}
.explore-locations{
    .default-css{
        margin-bottom: 70px;
        h3{ padding: 0; margin: 0 0 5px; font-size: 47px; }
        p{ font-size: 18px; line-height: 32px; }
    }
}
.facility{
    &:last-child{
        .facility-detail{
            margin-bottom:0;
        }
    }
    h3{
        color: #1a17af;
        font-size: 37px;
        line-height: 40px;
    }
    address{
        color: #585b60;
        font-size: 20px;
        line-height: 24px;
        padding: 0;
        margin: 0 0 10px;
    }
    .facility-detail{
        display: flex;
        align-items: stretch;
        margin-bottom: 45px;
    }
    .facility-photo{
        width: 66%;
        background-color: #EEE;
        margin-right: 6px;
        position: relative;
    }
    .facility-meta{
        padding: 30px 35px;
        width: calc(34% - 6px);
        background-color: #f8f8f8;
        font-size: 17px;
        line-height: 20px;
        p{
            font-size: inherit;
            line-height: inherit;
            &:first-of-type{
                margin-bottom: 40px;
                line-height: 26px;
            }
            &:not(:first-of-type){
                display: flex;
                margin-bottom: 17px;
                align-items: center;
                span{
                    width: calc(100% - 60px);
                    &:first-of-type{
                        width: 45px;
                        height: 45px;
                        border-radius: 50%;
                        background: $color-5;
                        display: block;
                        margin-right: 15px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    &.icon-boy-girl svg{
                       width: 38px;
                       height: 29px;
                    }
                    &.icon-committed svg{
                        width: 23px;
                        height: 23px;
                    }
                    &.icon-victim svg{
                        width: 6px;
                        height: 19px;
                    }
                    &.icon-abuse svg{
                        width: 13px;
                        height: 23px;
                    }
                }
            }
        }
    }
    .facility-closed{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        mix-blend-mode: multiply;
        background: linear-gradient(to bottom, rgb(130, 42, 68) 0%,rgba(137,82,84,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    .facility-closed-text{
        font-family: $font-2;
        color: #FFF;
        position: absolute;
        font-size: 48px;
        z-index: 4;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        border-bottom: 5px solid #FFF;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .facility .facility-closed{
        background:transparentize(darken(#822a44, 8), .2);
    }
}
@supports (-ms-ime-align:auto) {
    .facility .facility-closed{
        background:transparentize(darken(#822a44, 8), .2);
    }
}


.resource-link{
    padding: 0;
    margin: 0 0 20px;
    &:last-child{
        margin-bottom: 0;
    }
    a{
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        span:not(.icon){
            font-size: 19px;
            display: inline;
            color: #1a17af;
            position: relative;
            // border-bottom: 1px solid #1a17af;
            &:after{
                content: '';
                background-color: #1a17af;
                position: absolute;
                bottom: 2px;
                left: 0;
                height: 1px;
                width: 100%;
                transition: width 0.35s;
            }
        }
        &:hover{
            span:not(.icon){
                &:after{
                    width: 0;
                }
            }
        }
    }
    .icon{
        margin-right: 15px;
        svg{
            width: 26px;
            height: 33px;
            fill:#333a96
        }
    }
}
.explore-resources{
    padding-bottom: 90px;
}
.icon-pay{
    svg{
        width: 112px;
        height: 77px;
    }
}
.icon-pdf{
    svg{
        width: 26px;
        height: 33px;
    }
}


.icon-link{
    svg{
        width: 26px;
        height: 33px;
    }
}
.icon-img{
    svg{
        width: 26px;
        height: 33px;
    }
}



.icon-usa{
    svg{
        width: 63px;
        height: 40px;
    }
}
.explore-resources+.explore-back{
    padding-top: 0;
    padding-bottom: 50px;
}
.explore-back{
    padding: 50px 0 0;
}
.back-to-explore{
    text-transform: uppercase;
    text-align: center;
    color: #585b60;
    font-family: $font-1;
    // margin-top: 100px;
    display: block;
    svg{
        fill: #585b60;
    }
    span{
        display: block;
    }
    strong{
        display: block;
        font-weight: 400;
    }
}

@media only screen and (max-width : 920px) {
    .pie-chart{
        height: 200px;
    }
}
@media only screen and (max-width : 992px) {
    .site-header .container{
        // padding: 25px 15px;
    }
    .pie-container{
        > div:first-child {
            width: 46%;
            .pie-chart-meta{
                > div{
                    line-height: 30px;
                    strong{
                        font-size: 24px;
                        width: 50px;
                        small{
                            bottom: 5px;
                        }        
                    }
                    span{
                        font-size: 17px;
                    }
                } 
            } 
        }
        > div:last-child{
            width: 45%;
            .pie-chart-meta{
                > div{
                    line-height: 30px;
                    strong{
                        font-size: 20px;
                        width: 40px;
                        small{
                            bottom: 4px;
                        }        
                    }
                    span{
                        font-size: 15px;
                    }
                } 
            }
        }
        p {
            margin: 0 0 10px;
            font-size: 14px;
            line-height: 18px;
        }
    }
    .pie-svg{
        margin-right: 5px;
    }
    .explore-section {
        padding: 120px 0 100px;
        &.explore-back{
            padding: 50px 0 0;
        }
    }
    .explore-resources+.explore-back{
        padding-top: 0;
    }
    .explore-state-container{
        .cover.solid{
            .title{
                padding-left: 0;
            }
            .container{
                height: 300px;
            }
        } 
    }
    .explore-locations .default-css{
        margin-bottom: 30px;
    }
    .facility{
        .facility-meta{
            padding: 10px 15px;
            p:not(:first-of-type){
                span:first-of-type{margin-right: 5px;}
                span{ font-size: 15px; }
            } 
        }
    }
}
@media only screen and (max-width : 768px) {
    
    .pie-chart{
        height: auto;
        & + p{
            padding-left: 0;
        }
    }
    .pie-container{
        flex-direction: column;
        > div:first-child{ margin-bottom: 30px; }
        > div:last-child{ padding-left: 0; }
        > div:first-child, > div:last-child{ width: 100%; }
    }
    .pie-chart-meta > div{
        margin-bottom: 5px;
        span{
            white-space: normal;
            line-height: 1.2em;
            padding-top: 5px;
        }
        strong{
            line-height: 1.2em;
            small{
                font-size: 14px;
            }
        }
    }
    .chart-legend{
        margin-left: 10px;
    }
    .explore-menu{
        flex-direction: column;
        margin-bottom: 20px;
        > a{
            width: 100%;
            margin-bottom: 4px;
            font-size: 19px;
            line-height: 40px;
            height: 40px;
        }
    }
    .map-container{
        svg{
            max-width: 100%;
            width: 100%;
            max-height: 430px;
            margin: 15px auto 20px;
        }
        .mobile-only{
            // padding-top: 20px;
            strong{
                font-family: $font-2;
                font-weight: 900;
                font-size: 24px;
                margin-bottom: 8px;
                display: block;
            }
            select{
                width: 100%;
                margin-bottom: 7px;
            }
        }
    }
    .map-text{
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .explore-section {
        padding: 90px 0 60px;
    }
    .explore-state-container{
        .cover.solid{
            .title{
                font-size: 50px;
                line-height: 52px;
                padding: 0;
                text-align: center
            }
        }
    }
    .explore-section .section-desc{
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 30px;
    }
    .explore-locations .default-css{
        h3{
            font-size: 30px;
            line-height: 34px;
        }
        p{
            font-size: 16px;
            line-height: 24px;
        }
    } 
    .barchart-container{
        flex-direction: column;
        .bar-chart, .barchart-meta{
            width: 100%;
        }
        .bar-chart{
            margin-bottom: 30px;
        }
    }
    .facility{
        address{
            font-size: 16px;
            line-height: 20px;
        }
        .facility-detail{
            flex-direction: column;
            .facility-photo{
                width: 100%;
                height: 350px;
            }
            .facility-meta{
                width: 100%;
                p:first-of-type{
                    margin-bottom: 20px;
                }
            }
        }
    }
    .resource-link a span:not(.icon){
        font-size: 16px;
        line-height: 19px;
        &:after{
            bottom: 0;
        }
    }
}

.youth-facilities-inventory-container{
    margin-bottom: 60px;
    .default-css{
        margin-bottom: 0 !important;
        margin-top: 0 !important;
    }
    .facility{
        h3{
            margin-bottom: 10px;
            // font-family: "Tiempos Text";

        }
    }
    .fcties-title-container{
        display: flex;
        align-items: center;
        margin-bottom: 14px;
        h3{
            margin-bottom: 0;
            font-family: 'Tiempos Text';
            font-weight: 700;
            font-size: 27px;
            line-height: 30px;
        }
    }
    .facility-icon{
        display: flex;
        margin-right: 12px;
        svg{
            width: 45px;
            height: 45px;
        }
    }

    .CL.facility{
        svg{
            width: 80px;
        }
    }
}

@media only screen and (max-width : 768px) {
    .youth-facilities-inventory-container .fcties-title-container h3{
        font-size: 20px;
        line-height: 25px;
    }
    .youth-facilities-inventory-container .facility-icon svg{
        width: 35px;
        height: 35px;
    }
}

@media only screen and (max-width : 480px) {
    .explore-section {
        // padding: 80px 0 60px;
    }
    .bar-chart{
        flex-direction: column;
        align-items: flex-start;
        .bar-container.confinement-bar{
            margin-bottom: 30px;
        }
    }
    
}
@import "variables";
.watch{
    .arrow-container{
        background-color: #000;
        margin-bottom: 3px;
    }
}
.watch-page{
    background-color: #000;
}
.cover-slider{
    position: relative;
    overflow: hidden;
    height: 100vh;
    color: #fff;
    .watch-btn {
        cursor: pointer;
        color: #fff;
        font-family: $font-1;
        background-color: rgba($color-5, 0.8);
        border: 0;
        font-size: 19px;
        width: 250px;
        height: 56px;
        line-height: 56px;
        outline: none;
        transition: background-color 0.35s;
        display: inline-block;
        &:hover{
            background-color: rgba($color-25, 1);
        }
    }
    .v-desc{
        font-family: $font-3;
        line-height: 30px;
        font-size: 25px;
        padding: 0;
        margin: 0 0 20px;
    }
    .v-title{
        font-family: $font-4;
        font-size: 86px;
        line-height: 100px;
        padding: 0;
        margin: 0 0 0px;
        word-wrap: break-word;
        font-weight: 300;
    }
    .v-slide{
        height: 100vh;
        width: 100vw;
        position: relative;
        outline: none;
        overflow: hidden;
    }
    video.slide-video{
        width: 100%;
        height: 100vh;
        object-fit: cover;
        position: absolute;
        z-index: 2;
        top:0;
        left: 0;
    }
    .v-slide-text {
        z-index: 5;
        max-width: 925px;
        padding: 0 20px;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        text-align: center;
    }
}
.bglayer{
    position: absolute;
    width: 100%;
    height: 100%;
    background: no-repeat #231f20 right top;
    background-size: cover;
    top: 0;
    left: 0;
    z-index: 1;
}
.trans-layer{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(#000, 0.3);
    top: 0;
    left: 0;
    z-index: 3;
}

.vertical-dots{
    top: 0; right: 25px;
    position: absolute;
    z-index: 9;
    display: flex;
    align-items: center;
    height: 100%;
    ul{
        padding: 0;
        margin: 0;
        li{
            list-style: none;
            font-size: 18px;
            letter-spacing: 0.075em;
            line-height: 24px;
            margin-bottom: 5px;
            cursor: pointer;
            &.slick-active{
                div span{
                    background: #FFF;
                }
            }
        }
        div{
            span{
                width: 14px;
                height: 14px;
                display: inline-block;
                border: 2px solid #FFF;
                border-radius: 50%;
            }
        }
    }
}

.grid.videos-grid{
    margin-top: 12px;
    .grid-item{
		margin : 12px 0;
		&:nth-child(even){
			padding-left:12px;
		}
		&:nth-child(odd){
			padding-right:12px;
		}
		.post-title{
            font-size: 38px;
            max-width: 100%;
		}
		.post-desc{
            max-width: 400px;
            margin-top: 0;			
		}
		a{
            height: 400px;
            padding: 15px 60px;
			&:hover{
				.btn{
					background-color: $color-5;
				}
			}
		}
	}
}

.valign{
    text-align: center;
    position: absolute;
    top: 50%;
    margin: auto;
    left: 50%;
    transform: translate(-50%,-50%);
}
 .fixedPopup {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(black, 0.9);
    z-index: 999999;
    &:hover{
        .close-btn{
            opacity: 1;
        }
    }
    iframe{
        width: 100%;
        height: 100vh;
    }
    .close-btn{
        right: 20px; top: 20px;
        opacity: 0;
        transition: opacity 0.35s;
        cursor: pointer;
    }
    &.show{
        display: block;
    }
    .ytp-chrome-top-buttons{
        display: none;
    }
 }

.close-btn {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 1;
    z-index: 8;
    &:hover {
        opacity: 0.7;
    }
    &:before, &:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 33px;
        width: 2px;
        background-color: #FFF;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
}


@media only screen and (max-width : 992px) {
    .vertical-dots{
        height: auto;
        top:unset;
        bottom:10px;
        right: unset;
        left:50%;
        transform:translateX(-50%);
        ul li{
            display: inline-block;
            margin-right: 10px;
            bottom:10px;
        }
    }
}

@media only screen and (max-width : 768px) {
    .cover-slider {
        .v-title{
            font-size: 63px;
            line-height: 63px;
            margin-bottom: 15px;
        }
        .v-desc{
            line-height: 22px;
            font-size: 20px;
        }
        .watch-btn{
            width: 200px;
            height: 39px;
            line-height: 39px;
            font-size: 16px;
        }
    }    
}

@media only screen and (max-width : 480px) {
    .cover-slider{ 
        .v-title {
            font-size: 45px;
            line-height: 45px;
            margin-bottom: 10px;
       }
       .v-desc {
            line-height: 20px;
            font-size: 17px;
        }
    }   
}
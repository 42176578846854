@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

@font-face {
  font-family: "Tiempos Fine";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/TiemposFineWeb-Light.woff") format("woff"), url("../fonts/TiemposFineWeb-Light.woff2") format("woff2"); }

@font-face {
  font-family: "Tiempos Text";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/TiemposTextWeb-RegularItalic.woff") format("woff"), url("../fonts/TiemposTextWeb-RegularItalic.woff2") format("woff2"); }

@font-face {
  font-family: "Tiempos Text";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/TiemposTextWeb-Regular.woff") format("woff"), url("../fonts/TiemposTextWeb-Regular.woff2") format("woff2"); }

@font-face {
  font-family: "Tiempos Text";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/TiemposTextWeb-Bold.woff") format("woff"), url("../fonts/TiemposTextWeb-Bold.woff2") format("woff2"); }

@font-face {
  font-family: "National 2";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/National2Web-Regular.woff") format("woff"), url("../fonts/National2Web-Regular.woff2") format("woff2"); }

@font-face {
  font-family: "National 2";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/National2Web-Extrabold.woff") format("woff"), url("../fonts/National2Web-Extrabold.woff2") format("woff2"); }

@font-face {
  font-family: "National 2 Condensed";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/National2CondensedWeb-Extrabold.woff") format("woff"), url("../fonts/National2CondensedWeb-Extrabold.woff2") format("woff2"); }

@font-face {
  font-family: "Pitch Sans";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/PitchSansWeb-Light.woff") format("woff"), url("../fonts/PitchSansWeb-Light.woff2") format("woff2"); }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

html {
  overflow-x: hidden; }

a {
  text-decoration: none; }
  a.link-underline {
    color: black;
    border-bottom: 1px solid #e52c5a;
    padding-bottom: 4px;
    font-family: "Tiempos Text";
    font-size: 18px; }
    a.link-underline:hover {
      color: #e52c5a; }

.hidden {
  display: none !important; }

.show {
  display: flex; }

.container {
  max-width: 1430px;
  margin: auto;
  padding: 0 15px; }

body {
  font-family: "Tiempos Text"; }

h1, h2, h3, h4, h5, h6 {
  font-family: "National 2 Condensed"; }

h1 {
  font-size: 60px;
  line-height: 65px; }

h2 {
  font-size: 55px;
  line-height: 60px; }

h3 {
  font-size: 50px;
  line-height: 55px; }

h4 {
  font-size: 45px;
  line-height: 50px; }

h5 {
  font-size: 40px;
  line-height: 45px; }

h6 {
  font-size: 35px;
  line-height: 40px; }

img {
  max-width: 100%; }

strong {
  font-weight: 700; }

em {
  font-style: italic; }

ul {
  list-style-type: disc; }

ol {
  list-style-type: decimal; }

ul, ol {
  list-style-position: inside; }
  ul li, ol li {
    margin-bottom: 7px; }

blockquote {
  color: #e52c5a; }
  blockquote p {
    margin-bottom: 0 !important;
    font-size: 30px !important;
    position: relative;
    line-height: 35px !important; }
    blockquote p:before {
      content: '“';
      position: absolute;
      left: -13px; }
    blockquote p:after {
      content: '”'; }
  blockquote cite {
    display: block;
    text-align: right;
    font-size: 18px; }

p {
  line-height: 25px; }

figcaption {
  font-size: 12px;
  line-height: 16px;
  font-family: "Pitch Sans";
  font-weight: 300; }

.clearfix:after {
  content: "";
  display: table;
  clear: both; }

.content-container-inner {
  min-height: 100vh; }

.full-background {
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat; }

.btn {
  display: inline-block;
  color: #fff;
  background-color: #e52c5a;
  font-family: "National 2";
  font-size: 19px;
  height: 56px;
  text-transform: uppercase;
  line-height: 56px;
  padding: 0 32px;
  transition: all .5s;
  border: none;
  transition: background-color 0.35s; }
  .btn.blue {
    background-color: rgba(26, 23, 175, 0.8); }
    .btn.blue:hover {
      background-color: #1e1e1e; }
  .btn:hover {
    background-color: #1a17af; }

.content-left-container {
  display: flex;
  align-items: center; }

.content-center-container {
  display: flex;
  align-items: center;
  justify-content: center; }

.content-center-container-v {
  display: flex;
  align-items: center; }

.content-center {
  text-align: center; }

.site-container {
  background-color: #FFF; }
  .site-container.isLoading {
    overflow: hidden; }

body {
  width: 100%;
  height: 100%; }

.align-right {
  text-align: right; }

.align-center {
  text-align: center; }

.isSliding .site-footer-container, .isSliding .arrow-container {
  display: none; }

.slideup-enter {
  position: absolute;
  transform: translate(0, 100%);
  transform: translateY(100%) translateZ(0);
  width: 100%;
  height: 100%;
  overflow: hidden; }

.slideup-enter-active {
  transform: translate(0, 0);
  transform: translateY(0) translateZ(0);
  transition: transform 1s cubic-bezier(0.77, 0, 0.175, 1) 0s;
  z-index: 3; }

.slideup-exit-active {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform: translateY(-100vh) translateZ(0);
  transition: transform 1s cubic-bezier(0.77, 0, 0.175, 1) 0s; }

.slidedown-enter {
  position: absolute;
  transform: translate(0, -100%);
  width: 100%;
  height: 100%;
  overflow: hidden; }

.slidedown-enter-active {
  transform: translate(0, 0);
  transition: transform 1s ease-in-out; }

.slidedown-exit-active {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform: translate(0, 100%);
  transition: transform 1s ease-in-out; }

#spinner {
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: #1e1e1e;
  height: 100%;
  width: 100%; }
  #spinner.fadeIn {
    opacity: 1;
    height: 100%;
    transition: height 0ms 0ms, opacity 600ms 0ms; }
  #spinner.fadeOut {
    height: 0;
    opacity: 0;
    transition: height 0ms 400ms, opacity 400ms 0ms;
    overflow: hidden; }

.avoid-clicks {
  pointer-events: none; }

.site-container.experience {
  background-color: #000; }

@media only screen and (max-width: 768px) {
  .mobile-only {
    display: block; }
  .desktop-only {
    display: none; } }
